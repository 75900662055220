// This is a temporary file being used for POC. The real file will reside in pcl-ui-core instead of pcl-global-docroot once the POC has been tested. At that point, this file can be deleted.

/**
 * digitalData - dataLayer for princess.com
 * @type {object}
 */
window.digitalData = window.digitalData || {};

window.adobeTargetHelper = {
    /**
     * headEl - return a current version of <head> element on the page
     */
    headEl: function () { 
        return document.getElementsByTagName('head')[0];
    },

    /**
     * addStyle - append target prehiding <style id="at-body-style"> to <head>
     */
    addStyle: function () {
        var headEl = this.headEl();
        if (!headEl) {
            return;
        }
        var style = document.createElement('style');
        style.id = this.styleId;
        style.innerHTML = 'body {opacity: 0 !important}';
        headEl.appendChild(style);
    },

    /**
     * init - add prehiding style and start fallback timer
     */
    init: function () {
        if (window.digitalData.activity.ready === undefined) {
            this.addStyle();
            this.timer();
            if (window.digitalData.tracking.debug) {
                console.log(this.emoji, 'activity:', 'prehiding');
            }
        }
    },

    /**
     * removeStyle - remove target prehiding <style id="at-body-style"> from <head>
     */
    removeStyle: function (status) {
        var headEl = this.headEl();
        var styleEl = this.styleEl();
        if (!headEl || !styleEl) {
            return;
        }
        headEl.removeChild(styleEl);
    },

    /**
     * headEl - return a current version of <head> element on the page
     */
    styleEl: function () {
        return document.getElementById(this.styleId);
    },

    styleId: 'at-body-style',

    /**
     * status - records the current status of the dataLayer
     * @param  {booleean} staus   - the current status of 
     * @param  {[type]}  message  - any message to display in console for debugging
     */
    status: function (status, message) {
        this.removeStyle();
        window.digitalData.activity.ready = status;
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }
        
        if (window.digitalData.tracking.debug) {
            console.log(this.emoji, 'activity:', message, status);
        }
    },

    emoji: String.fromCodePoint(0x1F3AF),
    
    /**
     * timer - fallback timer to remove prehiding script at 3 seconds (adobe's recommendation)
     */
    timer: function () {
        this.timeout = setTimeout(function() {
            adobeTargetHelper.status(false, 'prehiding timeout');
        }, 3000);
    }
};

/**
 * digitalDataHelper - series of utility functions for building the dataLayer
 * @type {Object}
 */
window.digitalDataHelper = {
    /**
     * addData - helper function to add an object to a property in our dataLayer
     * @param {object} section - where in the dataLayer to add the data
     * @param {object} property - the property the data will be assigned
     * @param {object, string, array} data - the data to be added
     */
    addData: function (section, property, data) {
        // testing if property is defined
        if (typeof property === 'object') {
            data = property;
            property = undefined;
        }

        var flags = ['true','Y','1','false','N','0'], // values to convert to booleans
            excludeProperties = ['countryName','lastUpdated'], // properties to not include in dataLayer
            addData; // where to store clean data to insert into digitalData

        // clean data to set flags to boolean values
        function cleanData (data) {
            var flags = ['true','Y','1','false','N','0'],
                index = flags.indexOf(data);
            if (index > -1) {
                return (flags[index] === 'true' || flags[index] === 'Y' || flags[index] === '1');
            } else {
                return data;
            }
        }

        // loop through data to clean
        switch (typeof data) {
            case 'object':
                addData = {};
                for (var key in data) {
                    if (data.hasOwnProperty(key) && excludeProperties.indexOf(key) === -1) {
                        addData[key] = cleanData(data[key]);
                    }
                }
                break;
            case 'array':
                addData = [];
                data.forEach(function (element, index, array) {
                    addData[index] = cleanData(element);
                });
                break;
            default:
                addData = cleanData(data);
        }

        // populate digitalData
        if (!!property) {
            if (typeof addData === 'object') {
                window.digitalData[section][property] = window.digitalData[section][property] || {};
                for (var key in addData) {
                    window.digitalData[section][property][key] = addData[key];
                };
            } else {
                window.digitalData[section][property] = addData;
            }
        } else {
            window.digitalData[section] = window.digitalData[section] || {};
            for (var key in addData) {
                window.digitalData[section][key] = addData[key];
            };
        }
    },

    /**
     * env - string for current environment
     * @type {String}
     */
    env: hostDomain[sub_domain_substring].envir.charAt(0).toUpperCase() + hostDomain[sub_domain_substring].envir.slice(1),

    /**
     * library - to be loaded as needed
     * @type {Object}
     */
    library: {
        launch: hostDomain[sub_domain_substring].pclLaunchLibrary,
        onetrust: {
            src: hostDomain[sub_domain_substring].pclOnetrustLibrary,
            attributes: {
                'data-document-language': true,
                'data-domain-script': hostDomain[sub_domain_substring].pclOnetrustDomain,
                'charset': "UTF-8"
            }
        } 
    },

    /**
     * guestLocale - will add the guestLocale into to the user property in the dataLayer
     */
    guestLocale: function () {
        var userLocaleData = typeof localeData === 'object' ? localeData : typeof docCookies === 'object' && JSON.parse(docCookies.getItem('getLocale'));

        if (!!userLocaleData) {
            digitalDataHelper.addData('user', 'locale', userLocaleData);
            digitalDataHelper.trackingConsent(window.digitalData.user.locale.isEU, window.digitalData.user.locale.country);
        } else {
            document.addEventListener('DOMContentLoaded', function (event) {
                typeof guestLocale === 'object' && guestLocale.isReady(digitalDataHelper.guestLocale);
            });
        }
    },

    /**
     * guestFutureBooking - will add the futureBooking cookie to the user property in the dataLayer
     * @param  {object} dataObj - guestProfile.isReady will return the future booking object
     */
    guestFutureBooking: function (dataObj) {
        var futureBookedCookie = (this.env === 'Prod') ? 'pcl_futureBooking' : 'pcl_futureBooking' + this.env,
            futureBooking = (typeof docCookies === 'object' && docCookies.hasItem(futureBookedCookie)) ? JSON.parse(docCookies.getItem(futureBookedCookie)) : dataObj;

        // load the future booked data into the dataLayer from cookie when possible, or when it becomes available later
        if (futureBooking) {
            digitalDataHelper.addData('user', 'itinerary', futureBooking.futureBookings[0]);
        } else {
            document.addEventListener('DOMContentLoaded', function (event) {
                typeof guestProfile === 'object' && guestProfile.isReady('pcl_futureBooking', digitalDataHelper.guestFutureBooking);
            });
        }
    },

    /**
     * guestProfile - will add the guestInfo cookie to the user property in the dataLayer
     * @param  {object} dataObj - guestProfile.isReady will return the guest info object
     */
    guestProfile: function (dataObj) {
        var guestInfoCookie = (this.env === 'Prod') ? 'pcl_guestInfo' : 'pcl_guestInfo' + this.env,
            guestInfo = (typeof docCookies === 'object' && docCookies.hasItem(guestInfoCookie)) ? JSON.parse(docCookies.getItem(guestInfoCookie)) : dataObj;

        // load the guest info data into the dataLayer from cookie when possible, or when it becomes available later
        if (guestInfo) {
            digitalDataHelper.addData('user', 'profile', guestInfo);
        } else {
            document.addEventListener('DOMContentLoaded', function (event) {
                typeof guestProfile === 'object' && guestProfile.isReady('pcl_guestInfo', digitalDataHelper.guestProfile);
            });
            // populate eccn from url when guestProfile is not available
            var params = window.location.search;
            if (!!params && params.indexOf('&eccn') > 0) {
                params = params.substring(1);
                var paramArr = params.split('&');
                paramArr.forEach(function (param) {
                    if (param.indexOf('eccn') === 0) {
                        var profileObj = {ccn: param.split('=')[1]}
                        digitalDataHelper.addData('user', 'profile', profileObj);
                    }
                });
            }
        }

        // load future booking info when available
        if (window.digitalData.user.profile && window.digitalData.user.profile.futureBooked) {
            digitalDataHelper.guestFutureBooking();
        }
    },

    /**
         * guestSession - store / return timestamp for when the user's session began, used for traffic metrics
         */
    guestSession: function () {
        var sessionTimestamp;
        if (window.digitalData.user.session) {
            sessionTimestamp = window.digitalData.user.session;
        } else {
            var guestInfoStatusCookie = (this.env === 'Prod') ? 'pcl_guestInfoStatus' : 'pcl_guestInfoStatus' + this.env,
                guestInfoStatus = (typeof docCookies === 'object' && docCookies.hasItem(guestInfoStatusCookie)) ? JSON.parse(docCookies.getItem(guestInfoStatusCookie)) : null;

            sessionTimestamp = (!!guestInfoStatus) ? (typeof guestInfoStatus === 'object') ? guestInfoStatus.timeStamp : guestInfoStatus : null;
        }

        if (!!sessionTimestamp) {
            digitalDataHelper.addData('user', 'session', sessionTimestamp);
            return sessionTimestamp;
        }
    },

    /**
     * init - constructs top level of the dataLayer while preserving anything set by the applications, 
     *        and then load data into the dataLayer.
     */
    init: function () {
        const appBaseUrl = "https://js-cdn.dynatrace.com/jstag/1619ed8a359/bf07159iyr/";
        let scriptPath = "ea7c4b59f27d43eb_complete.js";
        if (this.env === 'Prod') {
            scriptPath = "84746b1bc55b6714_complete.js";
        }
        const fullScriptUrl = `${appBaseUrl}${scriptPath}`;
        this.loadScript(fullScriptUrl);

        var properties = ['activity', 'content', 'errors', 'events', 'itinerary', 'page', 'product', 'search', 'tracking', 'user'];

        // add the property if it's not defined
        properties.forEach(function (prop) {
            window.digitalData[prop] = window.digitalData[prop] || {};
        });

        var trackingProperties = {
            debug: localStorage.getItem('com.adobe.reactor.debug'),
            ready: window.digitalData.tracking.ready || false,
            satelliteReady: window.digitalData.tracking.satelliteReady || false
        }
        this.addData('tracking', trackingProperties); // set tracking statuses
        window.adobeTargetHelper.init(); // start target prehiding
        this.pageLoad(); // initial data for pageLoad
    },

    /**
     * pageLoad - loads default page and user data into digitalData.
     */
    pageLoad: function () {
        this.page(); // set page properties
        this.addData('user', 'auth', { status : typeof docCookies === 'object' && docCookies.hasItem('sccn') }); // set guest auth state
        this.guestLocale(); // set guest locale
        this.guestProfile(); // set guest profile
        this.guestSession(); // set guest session
    },

    /**
     *  routeLoad - used by single page applications to update page and user data in digitalData on route change 
     *            - if _satellite is not ready, store routeLoad in digitalData.tracking to call global-route-view in all pages dom ready rule
     * @param  {boolean} status - flag to indicate status of route load, false means the app errored out
     */
    routeLoad: function (status) {
        this.pageLoad(); // data for pageLoad
        var directCallRule = (status !== false) ? 'global-route-view' : 'global-error-view';
        if (window.digitalData.tracking.satelliteReady) {
            _satellite.track(directCallRule);
        } else {
            window.digitalData.tracking.routeLoad = directCallRule;
        }
    },

    /**
     *  directCall - used by single page applications and ajax forms to make a direct call
     *        - will send only if _satellite is ready, otherwise tar pits
     * @param  {string} directCallRule - name of direct call in Adobe Launch
     * @param  {object} data - data to be passed along in the direct call
     */
    directCall: function (directCallRule, data) {
        if (window.digitalData.tracking.satelliteReady) {
            data = data || {};
            _satellite.track(directCallRule, data);
        }
    },

    /**
     * loadConsent - will load the OneTrust plugin async. 
     *             - OneTrust will execute OptanonWrapper  onload of plugin, and when user updates settings
     */
    loadConsent: function (isEU, country) {
        window.OneTrust = {
            geolocationResponse: {
                stateCode: '',
                countryCode: country,
                regionCode: isEU ? 'EU' : '' // Optional parameter
            }
        };
        if (!window.OptanonWrapper) {
            // (!window.OptanonWrapper) prevents the following from being declared/loaded twice on SPAs, or else consent() will be invoked twice when the user clicks "Submit" or "Acknowledge" in OneTrust.
            window.OptanonWrapper = function () {
                digitalDataHelper.consent(isEU);
            }
            this.loadJS(digitalDataHelper.library.onetrust.src, true, 'head', digitalDataHelper.library.onetrust.attributes);
        }
    },

    /**
     * consent - adds consent values into user property in the dataLayer, then loads TMS.
     */
    consent: function (isRequired) {
        var groups = ['C0001','C0002','C0003','C0004','C0005'],
            activeGroups = window.OptanonActiveGroups ? window.OptanonActiveGroups.split(',') : [],
            consentGroups = {};

        groups.forEach(function (c) {
            consentGroups[c] = activeGroups.indexOf(c) > -1;
        });

        digitalDataHelper.addData('user', 'consent', consentGroups);

        if (isRequired && !consentGroups.C0002) {
            window.adobeTargetHelper.status(false, 'Consent not given');
            this.status('ready', false, 'Consent not given');
        }

        // move focus to top of page
        // window.focus({ preventScroll: true }); // Accessibility: Avoid "preventScroll" because it is problematic for keyboard users.

        // Re-trigger all applicable Launch rules when user submits consent levels:
        if (isRequired && docCookies.getItem('OptanonAlertBoxClosed') && !digitalData.user.consent.submitted) {
            /**
             * OptanonWrapper() invokes consent() twice: once when the banner is displayed, and again when the user submits their choices.
             * (!!docCookies.getItem('OptanonAlertBoxClosed')) ensures we only re-trigger all the Launch rules when the user is submitting.
             * (isRequired) ensures we only re-trigger all the Launch rules if consent was required in the first place. (If it wasn't, then all the rules already fired on page load and should not be re-triggered.)
             * digitalData.user.consent.submitted and OptanonAlertBoxClosed will only have this combination of true/false when the user is submitting their
               consent levels. After submitting on next route/page load submitted will be true and this will not trigger.
            **/
            if (!!consentGroups.C0002) {
                window.adobe?.optIn?.approve([window.adobe?.OptInCategories?.ECID, window.adobe?.OptInCategories?.ANALYTICS]); // This must be pushed to Adobe before we re-trigger all applicable Launch rules, or else the rules will fire but no b/ss network call will be sent.
            }
            this.directCall('user-consent-levels-submitted'); // Re-trigger all applicable Launch rules.
        }
    },

    /**
     * loadJS - load a JS file asynchronously
     * @param {string} src - the source of the script to be loaded
     * @param {boolean} ordered - load async (false for async, true for sync)
     * @param {string} position - 3 options - head will insert the script before this script tag, top immediately after opening body tag, bottom just before the closing body tag
     * @param {object} attributes - custom attributes to be appended to the script tag
     * @param {function} cb - call back function to run after the script has loaded
     **/
    loadJS: function(src, ordered, position, attributes, cb) {
        var tmp,
            ref = (position === 'head') ? window.document.getElementById('digital-data-tracking') : window.document.getElementsByTagName('body')[0],
            script = window.document.createElement('script');

        if (attributes && typeof(attributes) === 'object') {
            for (var attr in attributes) {
                script.setAttribute(attr, attributes[attr]);
            }
        }
        script.src = src;
        script.type = 'text/javascript';
        script.async = !ordered;
        if (position === 'head') {
            ref.parentNode.insertBefore( script, ref );
        } else {
            var bodyref = (position === 'top') ? ref.firstElementChild : null;
            ref.parentNode.insertBefore( script, bodyref );
        }
        if (cb) {
            switch (typeof cb) {
                case "function":
                    script.onload = cb;
                    break;
                case "object":
                    script.onload = function () {
                        window.digitalDataHelper.status(cb.property, true, cb.message);
                    }
                    script.onerror = function () {
                        window.digitalDataHelper.status(cb.property, false, cb.message);
                    }
                    break;
            }
        }
        return script;
    },

    /**
     * loadTMS - loads the tag manager library and sets datalayer to ready
     */
    loadTMS: function () {
        if (!window.digitalData.tracking.ready) {
            this.loadJS(digitalDataHelper.library.launch, false, 'head', null, { property: 'ready', message: 'Launch library loaded' });
        } else {
            this.status('ready', true, 'Launch already loaded');
        }
    },

    /**
     * page - constructs new page name using subdomain and pathname, sets if page is in an iframe or not, sets env and timestamp
     */
    page: function () {
        var page = {
            env: window.digitalData.page.env || this.env,
            pathname: window.location.pathname.split('.')[0],
            subdomain: window.location.host.split('.')[0],
            timestamp: Date.now(),
            type: window.digitalData.page.type || (window.self === window.top) ? 'self' : 'popup'
        }    
        this.addData('page', page);
    },

    /**
     * emoji - rocket
     **/
    emoji: String.fromCodePoint(0x1F680),

    /**
     * status - records the current status of the dataLayer, push traffic stats when setting ready status
     * @param  {string} property  - what status is being set in digitalData.tracking
     * @param  {booleean} staus   - the current status of property
     * @param  {[type]}  message  - any message to display in console for debugging
     */
    status: function (property, status, message) {
        window.digitalData.tracking[property] = status;
        // push page hit to traffic metrics
        // stopping ui-log calls to get rendered in digitalDataHelper
        // property === 'ready' && this.traffic(status);
        if (window.digitalData.tracking.debug) {
            console.log(this.emoji, 'digitalData:', message, JSON.parse(JSON.stringify(window.digitalData)));
        }
    },

    /**
     * trackingConsent - Sets "consent required" Boolean, along with all consent levels in data layer, then invokes loadConsent() accordingly.
     * @param  {Boolean} isEU - is user consent required?
     */
    trackingConsent: function (isEU, country) {
        const oneTrustConsentCookie = docCookies.getItem('OptanonConsent');
        var notrack = window.location.hash === '#notrack' || sessionStorage.getItem('pcl-notrack') === 'true';
        // Set consent defaults:
        const consentLevels = {
            'C0001': true, // always true
            'C0005': false, // always false because nothing is in consent level 5
            'submitted': false,
            'required': isEU
        }
        // Determine consent at page load
        if (docCookies.getItem('OptanonAlertBoxClosed') && oneTrustConsentCookie) { // set consent to values already agreed to in the cookie.
            consentLevels.C0002 = oneTrustConsentCookie.indexOf('C0002:1') > -1;
            consentLevels.C0003 = oneTrustConsentCookie.indexOf('C0003:1') > -1;
            consentLevels.C0004 = oneTrustConsentCookie.indexOf('C0004:1') > -1;
            consentLevels.submitted = true;
        } else { // if there is no existing onetrust cookies
            if (isEU) { // if consent is required, default to false 
                consentLevels.C0002 = false;
                consentLevels.C0003 = false;
                consentLevels.C0004 = false;
            }
            else { // if consent is not required, default to true
                consentLevels.C0002 = true;
                consentLevels.C0003 = true;
                consentLevels.C0004 = true;
            }
        }
        this.addData('user', 'consent', consentLevels);
        if (!notrack) {
            this.loadTMS(); // Moved this.loadTMS() out of this.consent (which fires later) and into this.trackingConsent (which fires sooner) to prevent timing issues with Adobe Target.
            this.loadConsent(isEU, country);
        } else {
            this.status('ready', false, 'notrack set');
            window.adobeTargetHelper.status(false, 'notrack set');
            sessionStorage.setItem('pcl-notrack', true);
        }
    },

    /**
     * traffic - push raw pageLoad traffic into logs for site performance metrics
     * @param  {Boolean} status - is tracking ready / activated based on users current consent / tracking setting
     */
    traffic: function (status) {
        if (!!window.fetch) {
            var data = { 'status': 'page traffic metrics' };

            // set title using page levels
            var pageLevels = [window.digitalData.page.level1, window.digitalData.page.level2, window.digitalData.page.level3, window.digitalData.page.level4];
            data.title = pageLevels.filter(function (level) { return (!!level) }).join(':');
            
            // set detail to indicate if tracking is loaded
            data.detail = 'page tracking status: ' + status;

            // collect basic stats in source
            data.source = "auth:" + window.digitalData.user.auth.status;
            data.source += ", country:" + window.digitalData.user.locale.country;
            data.source += ", pagename:" + window.digitalData.page.subdomain + ':' + window.digitalData.page.pathname;
            data.source += ", session:" + this.guestSession();

            // details for the fetch call
            var endpoint = $gwapiURL + '/guest/p1.0/ui-logs',
                companyCode;

            switch (window.digitalData.user.locale.country) {
                case 'GB':
                case 'UK':
                    companyCode = 'PO';
                    break;
                case 'AU':
                case 'NZ':
                    companyCode = 'PA';
                    break;
                default:
                    companyCode = 'PC';
            };

            var headers = {
                'Accept': '*/*',
                'BookingCompany': companyCode,
                'Content-Type': 'application/json',
                'pcl-client-id': $pclClientId,
                'ProductCompany': 'PC'
            }

            // make the fetch call to push the data
            fetch(endpoint, {
                method: 'post',
                headers: headers,
                body: JSON.stringify([data])
            })
                .then(function (response) { return response.json() })
                .then(function (data) {
                    if (window.digitalData.tracking.debug) {
                        console.log(window.digitalDataHelper.emoji, 'digitalData:', 'pageLoad Traffic data', data);
                    }
                })
                .catch(function (error) {
                    if (window.digitalData.tracking.debug) {
                        console.log(window.digitalDataHelper.emoji, 'digitalData:', 'pageLoad Traffic data error', error)
                    }
                });
        }
    },

    loadScript: function (url) {
        // Check if the script is already loaded
        const existingScript = Array.from(document.head.getElementsByTagName('script'))
            .find(script => script.src === url);    
        if (existingScript) {
            // Script is already loaded, do nothing
            return;
        }    
        // Create and load the script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onerror = () => console.error('Failed to load script:', url);
        document.head.appendChild(script);
    }
};

// prepare digitalData before loading launch library
digitalDataHelper.init();


// Logic to clear sessionStorage filter in currentBookingSession set from pcl-ube-ui
try {
    // If user is not currently in cruise-search(polar bear app)
    if (!window.location.pathname.startsWith('/cruise-search')) {
        let allSessionKeys = Object.keys(sessionStorage || {});
        // We clear filters from all the object that contain currentBookingSession
        allSessionKeys.forEach(key => {
            if(key.endsWith('-currentBookingSession')){
                const data = sessionStorage.getItem(key);
                if(data){
                    let bookingSession = JSON.parse(data);
                    // console.log('1',bookingSession)
                    delete bookingSession.filters;
                    // console.log('2',bookingSession)
                    sessionStorage.setItem(key, JSON.stringify(bookingSession));
                    // console.log('3', sessionStorage.getItem(key))
                }
            }
        })
    }
} catch(e){
    console.log('error', e)
}